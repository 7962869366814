import React from 'react';
import Discover from '../../assets/landing-page/discover.png'
import Planning from '../../assets/landing-page/planning.png'
import Design from '../../assets/landing-page/design.png'
import Development from '../../assets/landing-page/develop.png'
import Launch from '../../assets/landing-page/launch.png'

import leftArrow from '../../assets/previous.svg'
import More from '../../assets/more.svg'
import rightArrow from '../../assets/next.svg'

import '../../pages/landing-page/landing-page.styles.scss'

const About = () => (
<section id="about">
  <div className="container">
    <div className="container">
                <div className="section-heading2">
                    <span className="alt-font">About How we work</span>
                    <h3>Our Working Process</h3>
                </div>
            </div>
    
    {/* Nav tabs */}
    <div className="container-fluid no-padding">
      <div className="horizontaltab tab-style">
      <div className="container text-center mobileOnly">
        <img src={leftArrow} alt="logo" />
        <img src={More} alt="logo" />
        <img src={rightArrow} alt="logo" />

      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
      
        <span className="display-block sm-display-inline-block sm-vertical-align-top active" data-toggle="tab" href="#menu1"><i className="lni-support"></i><br/>Discover</span>
      </li>
      <li className="nav-item">
        <span className="display-block sm-display-inline-block sm-vertical-align-top " data-toggle="tab" href="#menu2"><i className="lni-vector"></i><br/>Planning</span>
      </li>
      <li className="nav-item">
        <span className="display-block sm-display-inline-block sm-vertical-align-top " data-toggle="tab" href="#menu3"><i className="lni-color-pallet"></i><br/>Design</span>
      </li>
      <li className="nav-item">
        <span className="display-block sm-display-inline-block sm-vertical-align-top " data-toggle="tab" href="#menu4"><i className="lni-website"></i><br/>Develop</span>
      </li>
      <li className="nav-item">
        <span className="display-block sm-display-inline-block sm-vertical-align-top " data-toggle="tab" href="#menu5"><i className="lni-rocket"></i><br/>Launch</span>
      </li>
    </ul>
      </nav>
       

            {/* Tab panes */}
            <div className="resp-tabs-container tab-content">
              <div id="menu1" className="container tab-pane active">
              <div className="container">
              <div className="row">
              <div className="col-lg-5 sm-margin-20px-bottom sm-text-center">
                  <img src={Discover} alt="" className="img-effect" />
              </div>
              <div className="col-lg-5 offset-lg-1">

                  <h5>Integration of  design</h5>
                  <p>The most important task at this point is to get a clear understanding of your future website purposes, the main goals you wish to get, and the target audience you want to attract to your site.</p>

                  <ul className="list-style no-margin-bottom">
                      <li>Solve your problem with us</li>
                      <li>We Provide Awesome Services</li>
                      <li>Your business deserves best software</li>
                  </ul>

                </div>
            </div>
              </div>
              </div>
              <div id="menu2" className="container tab-pane fade">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 order-lg-1 order-2">
                        <h5>Let’s build something great</h5>
                        <p>At this stage of the development cycle, we’ll create a workflow that will allow you to judge how the entire site will look like.</p>

                        <ul className="list-style no-margin-bottom">
                            <li>We create a sitemap sketch</li>
                            <li>Wireframe/mock-up</li>
                            <li>Select technology</li>
                        </ul>
                    </div>
                      <div className="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                          <img src={Planning} alt="" className="img-effect" />
                      </div>
                  </div>
                </div>
              </div>

              <div id="menu3" className="container tab-pane fade">
              <div className="container">
              <div className="row">
              <div className="col-lg-5 sm-margin-20px-bottom sm-text-center">
                  <img src={Design} alt="" className="img-effect" />
              </div>
              <div className="col-lg-5 offset-lg-1">
                  <h5>Now ready for design</h5>
                  <p>During the design phase, your website takes shape. All the visual content, such as images, photos, and videos is created at this step. Once again, all the info that was gathered through the first phase is crucial.</p>

                  <ul className="list-style no-margin-bottom">
                    <li>Creating Stunning design</li>
                    <li>Colourful Page Layouts</li>
                    <li>Review the layouts</li>


                  </ul>
              </div>
          </div>
              </div>
            </div>

            <div id="menu4" className="container tab-pane fade">
            <div className="container">
            <div className="row">
            <div className="col-lg-5 order-lg-1 order-2">
                <h5>Connect with development.</h5>
                <p>At this step, we can finally start creating the website itself. Graphic elements that have been designed during the previous stages should be used to create an actual website. Usually, the home page is created first, and then all sub-pages are added, according to the website hierarchy that was previously created during the planning phase.</p>

                <ul className="list-style no-margin-bottom">
                    <li>Research &amp; Devloping</li>
                    <li>Fully customizable</li>
                    <li>Solve your problem faster</li>
                    <li>There are many variations</li>
                </ul>

            </div>
            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1 sm-text-center sm-margin-20px-bottom">
                <img src={Development} alt="" className="img-effect" />
            </div>
        </div>
            </div>
          </div>

          <div id="menu5" className="container tab-pane fade">
          <div className="container">
          <div className="row">
          <div className="col-lg-5 sm-margin-20px-bottom sm-text-center">
              <img src={Launch} alt="" className="img-effect" />
          </div>
          <div className="col-lg-5 offset-lg-1">
              <h5>You have a ready site</h5>
              <p>What’s important to remember is that a website is more of a service than a product. You should also make sure that everything works fine, and everybody is satisfied. We will always be prepared to make changes in another case.</p>

              <ul className="list-style no-margin-bottom">
                  <li>One click and site ready to use</li>
                  <li>Launch Your Project</li>
                  <li>Life time supports</li>
                  </ul>
          </div>
      </div>
          </div>
        </div>
            </div>
      
      </div>
    </div>
  </div>
</section>

)

export default About;