const ADMIN_DATA = [
  {
      title: 'Updates',
      subTitle: 'Enquiries',
      imageUrl: '',
      id: 1,
      linkUrl: 'dashboard/admin/updates',
    },
    {
      title: 'Plugin',
      subTitle: 'Enquiries',
      imageUrl: '',
      id: 2,
      linkUrl: 'dashboard/admin/plugin'
    },
    {
      title: 'Analytics',
      subTitle: 'Dashboard',
      size: 'large',
      id: 3,
      linkUrl: 'dashboard/admin/analytics',
      imageUrl:''
    }
  
  ]


export default ADMIN_DATA;