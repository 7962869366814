import React from 'react';
import { ReactComponent as ServiceImage } from '../../assets/landing-page/testimonials-2-men-talking.svg'

import '../../pages/landing-page/landing-page.styles.scss'


const Footer = () => (
    <section id="contact">
    <div className="form-1">
        <div className="container">
        <div className="section-heading2">
        <span className="alt-font">Request a meeting</span>
        <h3>Contact</h3>
        </div>
            <div className="row">
            <div className="col-lg-6">
 
                <div id="contact-form" className="image-container">
                        <ServiceImage className="img-fluid" alt="alternative" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-container">
                    <form id="requestForm" method="post" encType="multipart/form-data" data-netlify="true" data-netlify-recaptcha="true">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="row">
                    <div className="col-md-6">
                    <div className="form-group">
                    <input className="form-control-input notEmpty" type="text" name="name" placeholder="Full Name" />
                      <div className="help-block with-errors"></div>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                    <input className="form-control-input notEmpty" type="number" name="number" placeholder="Phone Number" />

                      <div className="help-block with-errors"></div>
                    </div>
                    </div>

                    </div>
                    <div className="form-group">
                    <input className="form-control-input notEmpty" type="email" name="email" placeholder="Email" />
                      <div className="help-block with-errors"></div>
                    </div>

                      <div className="custom-file mb-3">
                        <input type="file" className="custom-file-input" id="customFile" name="filename" />
                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                      </div>
                    <div className="form-group">

                      <select name="category" className="category-input">
                          <option value="Select Category" >Select Category</option> 
                          <option value="General Enquiries">General Enquiries</option>
                          <option value="Need a quote or advice on a project" >Need a quote or advice on a project</option>
                          <option value="Want to provide services to Rational Solutions">Want to provide services to Rational Solutions</option>
                          <option value="Like to work for Rational Solutions">Like to work for Rational Solutions</option>
                        </select>

                      <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                    <textarea className="form-control-textarea notEmpty" name="message" placeholder="Message" />

                      <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                    <button type="submit" className="form-control-submit-button disabled">REQUEST</button>
                </div>
                  </form>
                    </div> 

                </div> 
            </div> 
        </div> 
        </div>
        <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-5">
                <div className="footer-col">
                <h6>About Rational Solutions</h6>
                <p>We're passionate about offering some of the best business growth services for startups</p>
            </div>
                </div> 
                <div className="col-md-5">
                          <div className="footer-col">
                          <h6>Important Links</h6>
                          <ul className="list-unstyled li-space-lg">
                              <li className="media">
                                  <p>Read our <a className="turquoise" href="/" data-toggle="modal" data-target="#exampleModalTc">Terms &amp; Conditions</a>, <a className="turquoise" href="/" data-toggle="modal" data-target="#exampleModalPp">Privacy Policy</a></p>
                              </li>
                          </ul>                              
                          </div> 
                      </div>
            </div> 
        </div> 
          <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright &copy; Rational Solutions Ltd.</p>
                        </div> 
                    </div> 
                </div> 
            </div>
      </div>
      <div className="modal fade" id="exampleModalTc" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Terms &amp; Conditions</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          TERMS AND CONDITIONS

          Last Updated 11 March  2020  
          
          
          1.   Agreement to Terms     
           
          1.1  These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (you), and Rational Solutions Ltd., concerning your access to and use of the Rational Solutions Ltd. (https://www.rationalsolutions.co.uk/) website as well as any related applications (the Site).  
          
          The Site provides the following services: Digital Web Agency (Services). You agree that by accessing the Site and/or Services, you have read, understood, and agree to be bound by all of these Terms and Conditions.  
          
          If you do not agree with all of these Terms and Conditions, then you are prohibited from using the Site and Services and you must discontinue use immediately. We recommend that you print a copy of these Terms and Conditions for future reference.  
          
          1.2  The supplemental policies set out in Section 1.7 below, as well as any supplemental terms and condition or documents that may be posted on the Site from time to time, are expressly incorporated by reference.  
          
          1.3  We may make changes to these Terms and Conditions at any time. The updated version of these Terms and Conditions will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. You are responsible for reviewing these Terms and Conditions to stay informed of updates. Your continued use of the Site represents that you have accepted such changes.  
          
          1.4  We may update or change the Site from time to time to reflect changes to our products, our users' needs and/or our business priorities.  
          
          1.5  Our site is directed to people residing in United Kingdom. The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.   
          
          1.6  The Site is intended for users who are at least 18 years old.  If you are under the age of 18, you are not permitted to register for the Site or use the Services without parental permission. 
          
          
          2.    Acceptable Use    
          
          2.1  Our full Acceptable Use Policy, sets out all the permitted uses and prohibited uses of this site.    
          
          2.2  You may not access or use the Site for any purpose other than that for which we make the site and our services available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.  
          
          2.3  As a user of this Site, you agree not to:  
           
          ● Falsely imply a relationship with us or another company with whom you do not have a relationship     
          
          3.    Content you provide to us  
          
          3.1  There may be opportunities for you to post content to the Site or send feedback to us (User Content). You understand and agree that your User Content may be viewed by other users on the Site, and that they may be able to see who has posted that User Content.     
          
          3.2  You warrant that any User Content does comply with our Acceptable Use Policy, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of this warranty. 
          
          3.3  We have the right to remove any User Content you put on the Site if, in our opinion, such User Content does not comply with the Acceptable Use Policy.  
          
          3.4  We are not responsible and accept no liability for any User Content including any such content that contains incorrect information or is defamatory or loss of User Content. We accept no obligation to screen, edit or monitor any User Content but we reserve the right to remove, screen and/or edit any User Content without notice and at any time. User Content has not been verified or approved by us and the views expressed by other users on the Site do not represent our views or values 
          
          3.5  If you wish to complain about User Content uploaded by other users please contact us at rationalsolutions.uk@gmail.com.     
          
          4.   Our content    
          
          4.1  Unless otherwise indicated, the Site and Services including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (Our Content) are owned or licensed to us, and are protected by copyright and trade mark laws.   
          
          4.2  Except as expressly provided in these Terms and Conditions, no part of the Site, Services or Our Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. 
          
          4.3  Provided that you are eligible to use the Site, you are granted a limited licence to access and use the Site and Our Content and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.   
          
          4.4  You shall not (a) try to gain unauthorised access to the Site or any networks, servers or computer systems connected to the Site; and/or (b) make for any purpose including error correction, any modifications, adaptions, additions or enhancements to the Site or Our Content, including the modification of the paper or digital copies you may have downloaded. 
          
          4.5  We shall (a) prepare the Site and Our Content with reasonable skill and care; and (b) use industry standard virus detection software to try to block the uploading of content to the Site that contains viruses.  
          
          4.6  The content on the Site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content on the Site.  
          
          4.7  Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that Our Content on the Site is accurate, complete or up to date.  
          
          5.    Site Management     
          
          5.1  We reserve the right at our sole discretion, to (1) monitor the Site for breaches of these Terms and Conditions; (2) take appropriate legal action against anyone in breach of applicable laws or these Terms and Conditions; (3) refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any of your Contributions; (4) remove from the Site or otherwise disable all files and content that are excessive in size or are in any way a burden to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site and Services.  
          
          5.2  We do not guarantee that the Site will be secure or free from bugs or viruses. 
          
          5.3  You are responsible for configuring your information technology, computer programs and platform to access the Site and you should use your own virus protection software.  
          
          6.    Modifications to and availability of the Site     
          
          6.1  We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.    
          
          6.2  We cannot guarantee the Site and Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site or Services during any downtime or discontinuance of the Site or Services.We are not obliged to maintain and support the Site or Services or to supply any corrections, updates, or releases. 
          
          6.3  There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information at any time, without prior notice.  
          
          7.    Disclaimer/Limitation of Liability     
          
          7.1  The Site and Services are provided on an as-is and as-available basis. You agree that your use of the Site and/or Services will be at your sole risk except as expressly set out in these Terms and Conditions. All warranties, terms, conditions and undertakings, express or implied (including by statute, custom or usage, a course of dealing, or common law) in connection with the Site and Services and your use thereof including, without limitation, the implied warranties of satisfactory quality, fitness for a particular purpose and non-infringement are excluded to the fullest extent permitted by applicable law.  
          
          We make no warranties or representations about the accuracy or completeness of the Site’s content and are not liable for any (1) errors or omissions in content: (2) any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored on our server; (3) any interruption or cessation of transmission to or from the site or services; and/or (4) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party. We will not be responsible for any delay or failure to comply with our obligations under these Terms and Conditions if such delay or failure is caused by an event beyond our reasonable control. 
          
          7.2  Our responsibility for loss or damage suffered by you: 
          
          Whether you are a consumer or a business user: 
          
          ● We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.  
          
          ● If we fail to comply with these Terms and Conditions, we will be responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms and Conditions, but we would not be responsible for any loss or damage that were not foreseeable at the time you started using the Site/Services.  
          
          Notwithstanding anything to the contrary contained in the Disclaimer/Limitation of Liability section, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to a total aggregate amount equal to the greater of (a) the sum of £5000 or (b) the amount paid, if any, by you to us for the Services/Site during the six (6) month period prior to any cause of action arising.   
          
          If you are a business user: 
          
          We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: 
          
          ● use of, or inability to use, our Site/Services; or  
          ● use of or reliance on any content displayed on our Site.  
          
          In particular, we will not be liable for: 
          
          ● loss of profits, sales, business, or revenue;  
          ● business interruption;  
          ● loss of anticipated savings;  
          ● loss of business opportunity, goodwill or reputation; or  
          ● any indirect or consequential loss or damage.    
          
          If you are a consumer user: 
          
          ● Please note that we only provide our Site for domestic and private use. You agree not to use our Site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. 
          
          ● If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.   
          
          ● You have legal rights in relation to goods that are faulty or not as described. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Nothing in these Terms and Conditions will affect these legal rights.     
          
          8.    Term and Termination     
          
          8.1  These Terms and Conditions shall remain in full force and effect while you use the Site or Services or are otherwise a user of the Site, as applicable. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at rationalsolutions.uk@gmail.com.  
          
          8.2  Without limiting any other provision of these Terms and Conditions, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the Site and the Services (including blocking certain IP addresses), to any person for any reason including without limitation for breach of any representation, warranty or covenant contained in these Terms and Conditions or of any applicable law or regulation.  
          
          If we determine, in our sole discretion, that your use of the Site/Services is in breach of these Terms and Conditions or of any applicable law or regulation, we may terminate your use or participation in the Site and the Services or delete any content or information that you posted at any time, without warning, in our sole discretion.  
          
          8.3  If we terminate or suspend your account for any reason set out in this Section 9, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.   
            
          9.    General       
          
          9.1  Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.  
          
          You hereby agree to the use of electronic signatures, contracts, orders and other records and to electronic delivery of notices, policies and records of transactions initiated or completed by us or via the Site. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by other than electronic means.  
          
          9.2  These Terms and Conditions and any policies or operating rules posted by us on the Site or in respect to the Services constitute the entire agreement and understanding between you and us.   
          
          9.3  Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.   
          
          9.4  We may assign any or all of our rights and obligations to others at any time.   
          
          9.5  We shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond our reasonable control.   
          
          9.6  If any provision or part of a provision of these Terms and Conditions is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.  
          
          9.7  There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site or Services. 
          
          9.8  For consumers only  - Please note that these Terms and Conditions, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction expect that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland. If you have any complaint or wish to raise a dispute under these Terms and Conditions or otherwise in relation to the Site please follow this link http://ec.europa.eu/odr  
          
          9.9  For business users only - If you are a business user, these Terms and Conditions, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English Law. We both agree to the exclusive jurisdiction of the courts of England and Wales.   
          
          9.10  The following are trade marks of Rational Solutions Ltd.. You are not permitted to use them without our approval, unless they are part of material our Site explicitly states you are permitted to use.     
          
          9.11  A person who is not a party to these Terms and Conditions shall have no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these Terms and Conditions. 
          
          9.12  In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us by email at rationalsolutions.uk@gmail.com: 
          
           
          Rational Solutions Ltd.  
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="exampleModalPp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Privacy Policy</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        PRIVACY NOTICE

        Last updated __________  
        
        
        
        
        
        
        
        Thank you for choosing to be part of our community at Rational SOlutions Ltd. (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at rationalsolutions.uk@gmail.com.
        
        When you visit our and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our and our services.
        
        This privacy policy applies to all information collected through our and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites"). 
        
        Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.  
        
        
        1. WHAT INFORMATION DO WE COLLECT?  
    
            
        Personal information you disclose to us 
        
        In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.  
        
        We collect personal information that you voluntarily provide to us when registering at the  expressing an interest in obtaining information about us or our products and services, when participating in activities on the (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.
        
        The personal information that we collect depends on the context of your interactions with us and the , the choices you make and the products and features you use. The personal information we collect can include the following:
        
        Name and Contact Data. We collect your first and last name, email address, postal address, phone number, and other similar contact data.
        
        Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.
        
        Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions. 
        
        Social Media Login Data. We provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below. 
        
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.  
        
        
        
        Information automatically collected 
        
        In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our .
        
        We automatically collect certain information when you visit, use or navigate the . This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes. 
        
        Like many businesses, we also collect information through cookies and similar technologies.  
        
        
        
        Information collected from other sources 
        
        In Short: We may collect limited data from public databases, marketing partners, and other outside sources.   
        
        We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links).  
        
        
        
        2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?  
        
        In Short: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.   
        
        
        We may process or share data based on the following legal basis:
        Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose. 
        
        Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests. 
        
        Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. 
        
        Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). 
        
        Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
        More specifically, we may need to process your data or share your personal information in the following situations:   
        
        Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the , which will enable them to collect data about how you interact with the over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.  
        
        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.  
        
        Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the . These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.   
        
        Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.      
        
        Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.   
        
        Other Users. When you share personal information or otherwise interact with public areas of the , such personal information may be viewed by all users and may be publicly distributed outside the in perpetuity. If you interact with other users of our and register through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our , and view your profile.   
        
        3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        In Short: We may use cookies and other tracking technologies to collect and store your information.  
        
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
        
        
        
        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?  
        
        In Short: If you choose to register or log in to our websites using a social media account, we may have access to certain information about you.  
        
        Our offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information you choose to make public.  
        
        We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the . Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.  
        
        
        
        5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? 
        
        In Short: We may transfer, store, and process your information in countries other than your own.  
        
        Our servers are located in. If you are accessing our from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries. 
        
        If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.  
      
        
        
        6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? 
        
        In Short: We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our websites.  
        
        The may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the . You should review the policies of such third parties and contact them directly to respond to your questions. 
        
        
        
        7. HOW LONG DO WE KEEP YOUR INFORMATION? 
        
        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.  
        
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 90 Days.
        
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        
        
        
        8. HOW DO WE KEEP YOUR INFORMATION SAFE? 
        
        In Short: We aim to protect your personal information through a system of organisational and technical security measures. 
        
        We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our is at your own risk. You should only access the services within a secure environment. 
        
        
        
        9. DO WE COLLECT INFORMATION FROM MINORS?
        
        In Short: We do not knowingly collect data from or market to children under 18 years of age.
        
        We do not knowingly solicit data from or market to children under 18 years of age. By using the , you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the . If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at rationalsolutions.uk@gmail.com. 
        
        
        
        10. WHAT ARE YOUR PRIVACY RIGHTS? 
        
        In Short: In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
        
        In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws. 
        
        If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
        
        If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm 
         
        
        Account Information 
        
        If you would at any time like to review or change the information in your account or terminate your account, you can:  
        
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.  
        
        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:
        
        11. CONTROLS FOR DO-NOT-TRACK FEATURES  
        
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. 
          
        
        12. DO WE MAKE UPDATES TO THIS POLICY?
        
        In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
        
        We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information. 
        
        
        
        14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        
        If you have questions or comments about this policy, you may email us at rationalsolutions.uk@gmail.com:
        </div>
      </div>
    </div>
  </div>
</section>
)

export default Footer;