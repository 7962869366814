import firebase from 'firebase/app';    
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyDbYrHrHmQknSG1IfRB3eZvvInU9oaq-dI",
    authDomain: "wixish-db.firebaseapp.com",
    databaseURL: "https://wixish-db.firebaseio.com",
    projectId: "wixish-db",
    storageBucket: "wixish-db.appspot.com",
    messagingSenderId: "37663983552",
    appId: "1:37663983552:web:15aadbe52934cb000caa25",
    measurementId: "G-8KVGRMVPDQ"
  };

  export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;

    const userRef = firestore.doc(`user/${userAuth.uid}`);

    const snapShot = await userRef.get();

    if(!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
    

    try {
        await userRef.set({
            displayName, 
            email,
            createdAt,
            ...additionalData
        })

    } catch (error) {
        console.log('error creating user', error.message);
    }
}

return userRef;
};

  firebase.initializeApp(config);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();

  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  export const signInWithGoogle = () => auth.signInWithPopup(provider);

  export default firebase;


