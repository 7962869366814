import React from 'react';

import Hero2 from '../../assets/landing-page/heroImg2.png'

import '../../pages/landing-page/landing-page.styles.scss'

const Services = () => (
   <section id="services">
    <div className="services-area">
    <div className="container">
        <div className="section-heading2">
            <span className="alt-font">Discover what it can do for your business organization right away.</span>
            <h3>Services</h3>
        </div>
        <div className="row">
        <div className="col-lg-5 sm-margin-20px-bottom sm-text-center">
        <img src={Hero2} alt="" className="img-effect" />
    </div>
        <div className="col-lg-5 offset-lg-1">
        <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-code-alt"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Web Design
                    </h6>
                </div>
            </div> 
        </div>
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-color-pallet"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Graphic Design
                    </h6>
                </div>
            </div> 
        </div>
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-mobile"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Responsive Design
                    </h6>
                </div>
            </div> 
        </div>
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-vector"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Planning UI/UX
                    </h6>
                </div>
            </div> 
        </div>
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-website"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Web Development
                    </h6>
                </div>
            </div> 
        </div>
        <div className="col-lg-6 col-md-6 col-sm-8">
            <div className="single-service text-center mt-30">
                <div className="service-icon img-effect">
                    <i className="lni-support"></i>
                </div>
                <div className="service-content">
                    <h6 className="service-title">
                    <p></p>
                    Consultancy and Support
                    </h6>
                </div>
            </div> 
        </div>
    </div> 
        </div>

        </div>

 
    </div> 
    </div>
</section>
)


export default Services;