import React from 'react';
// import App from './App';


import Header from '../../components/landing-page/header.component'
import Hero from '../../components/landing-page/hero.component'
import Services from '../../components/landing-page/services.component'
import Features from '../../components/landing-page/features.component'
import About from '../../components/landing-page/about.component'
import Footer from '../../components/landing-page/footer.component'
import Join from '../../components/ChatRoom/Join/Join'
// import Chat from '../../components/ChatRoom/Chat/Chat'

import './landing-page.styles.scss'
import '../../components/landing-page/css/default.css'
import '../../components/landing-page/css/style.css'

const LandingPage = () => (
   <div className="landing-container">
   <Header />
   <Hero />
   <Services />
   <About />
   <Features />

   <Footer />
   <Join />

    <div className="hideForNow">

    </div>
   </div>
       
    
)

export default LandingPage;