import React from 'react';

import MenuItem from '../menu-item/menu-item.component'

import './directory.component.styles.scss'

class Directory extends React.Component {
    constructor() {
        super();

        this.state = {
            sections: [
                {
                  id: 1,
                  title: 'View',
                  subTitle: 'Website',
                  linkUrl: '',
                  imageUrl:''
                },
                {
                  id: 2,
                  title: 'Updates',
                  subTitle: 'Enquiries',
                  imageUrl: '',
                  linkUrl: '/admin/updates'
                },
                {
                  id: 3,
                  title: 'Plugin',
                  subTitle: 'Enquiries',
                  imageUrl: '',
                  linkUrl: '/admin/plugin'
                },
                {
                  id: 4,
                  title: 'Analytics',
                  subTitle: 'Dashboard',
                  size: 'large',
                  linkUrl: '/admin/analytics',
                  imageUrl: ''

                },
                {
                  id: 5,
                  title: 'Admin',
                  subTitle: 'Dashboard',
                  size: 'large',
                  linkUrl: '/admin',
                  imageUrl:''
                }
              ]
        }
    }

    render() {
      return (
          <div className="directory-menu">
              {
                  this.state.sections.map(({ id, ...otherSectionProps}) => (
                      <MenuItem key={id} {...otherSectionProps} />
                  ))
              }
          </div>

      )
  }
}

export default Directory;