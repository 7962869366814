import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// import brandLogo from '../../../assets/logo.png'
import { ReactComponent as ChatIcon } from '../../../assets/conversation.svg'
import { ReactComponent as CloseIcon } from '../../../assets/cancel.svg'
import { ReactComponent as EnterIcon } from '../../../assets/origami.svg'

// import closeIcon from '../../../icons/closeIcon.png';

import './Join.scss';

const Join = () => {
    const [name, setName] = useState('');
    const [room, setRoom] = useState('');

    return (
        
        <div className="chat-popup collapse" id="chatRoom">
        <div className="chatHeader card-header">
        <ChatIcon />
        <span className="chatHeaderText">Speak to our team</span>
        <CloseIcon id="chatCloseIcon" href="#chatRoom" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="chatRoom"/>

        </div>
         <div className="joinOuterContainer">
            <div className='joinInnerContainer'>

                <div><input placeholder="Name" className="joinInput" type="text" onChange={(event) => setName(event.target.value)} /></div>
                <div>
                <select placeholder="Room" className="joinInput mt-20" type="text" onChange={(event) => setRoom(event.target.value)}> 
                    <option value="General Enquiries">Select a room</option>
                    <option value="General Enquiries">General Enquiries</option>
                    <option value="Need a quote or advice on a project" >Need a quote or advice on a project</option>
                </select>
                
                </div>
                <div className="btn-position">
                <Link onClick={event => (!name ||!room) ? event.preventDefault() : null} to={`/chat?name=${name}&room=${room}`}>
                <button className="btn-chat mt-20 mx-width pd-10" type="submit"><EnterIcon /></button>
                </Link>
                </div>
      
            </div>
            </div>
        </div>
    )
}

export default Join;