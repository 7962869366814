import React from 'react';
import { Link } from 'react-router-dom';
import SignIn from '../../components/sign-in/sign-in.component'
import SignUp from '../../components/sign-up/sign-up.component'
import brandLogo from '../../assets/logo.png'

import './sign-in-and-sign-up.styles.scss'

const SignInAndSignUpPage = () => (
    <div className='page-content'>
    <Link to="/">
    <img  src={brandLogo} className='brandLogo' alt="logo"  path="/" />
    </Link>
        <div className='sign-in-and-sign-up'>
            <SignIn />
            <SignUp />
        </div>
    </div>
)
export default SignInAndSignUpPage;