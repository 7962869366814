import React from 'react';

import '../../pages/landing-page/landing-page.styles.scss'

import './css/owl.carousel.css'
const Features = () => (
  <section id="features">
    <div id="">
    <div className="section-heading2">
    <span className="alt-font">Discover</span>
    <h3>Design Ideas</h3>
</div>
    <div className="container">
        <div className="row">
        <div className="col-lg-5 sm-margin-20px-bottom sm-text-center">
          <a data-pin-do="embedBoard" data-pin-scale-height="300" data-pin-scale-width="200" href="https://www.pinterest.co.uk/rationalsolutions/design-ideas/" samesite="None">Website Inspiration</a>
        </div>
        <div className="col-lg-5 offset-lg-1 feature_text">
          <h5>Start your discovery</h5>
          <p>The most important task at this point is to get a clear understanding of your future website purposes, the main goals you wish to get, and the target audience you want to attract to your site.</p>
        
          <ul className="list-style">
            <li className="hideForMobile">Design your own layout on our 'Page Builder' & Screenshot (Desktop Only)</li>
            <li>Select a pin from our board or any on Pinterest</li>
            <li>Save the image as...</li>
            <li>Include the image on our request form below.</li>
  
          </ul>
          <div className="feature__buttons">
          <a className="btn-solid-lg page-scroll mr-20" href="https://www.pinterest.co.uk/rationalsolutions/design-ideas/more_ideas/?ideas_referrer=1">Discover more</a>
      
          <a className="btn-solid-lg page-scroll hideForMobile" href="https://rationalsolution-builder.netlify.com/">Page Builder</a>
          </div>

        </div>
      </div>
    </div>

    </div>
  </section>
)

export default Features;