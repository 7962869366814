import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Icon } from '../../assets/login.svg'
import { ReactComponent as Chat } from '../../assets/talk.svg'

import brandLogo from '../../assets/logo.png'
// import Consult from './consult-form.component'
import '../../pages/landing-page/landing-page.styles.scss'

const Header = () => (
    <header id="header">

        <a href="#home" className="logo"><img src={brandLogo} className='landing-logo' alt="logo" /></a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
        <ul className="menu">
        <div className="flex">
        <li><a href="#services">Services</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#features">Discover</a></li>

            <li><a href="#contact">Contact</a></li>
            <li className=""><a href="#chatRoom" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="chatRoom">
            <Chat className='chatRoom' />
            </a></li>
        </div>


        <li className="hideForNow"><Link to="/signin">
        <Icon className='login' />
        </Link></li>
        </ul>
    </header>
    
)

export default Header;

