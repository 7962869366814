import React from 'react';
// import App from './App';

import Directory from '../../components/directory/directory.component'

import './dashboard.styles.scss'

const Dashbaord = ({ currentUser }) => (
    <div className="dashbaord">

        <Directory />
    </div>
    
)

export default Dashbaord;