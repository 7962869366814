import React from 'react';

import '../../pages/landing-page/landing-page.styles.scss'
import Hero1 from '../../assets/landing-page/heroImg1.png'

const Hero = () => (
    <section id="home" >
        <div className="services-area pt-30">
            <div className="header-content"> 
            <div className="container"> 
                <div className="row">
                <div className="col-lg-6">
                    <div className="text-container">
                        <h1><span className="turquoise">Rational Solutions</span> Development Agency</h1>
                        <p className="p-large">Join Rational Solutions to promote your business/startup and generate leads for the offered services.</p>
                        <a className="btn-solid-lg page-scroll" href="#services">What we offer</a>
                    </div>
                </div>
                    <div className="col-lg-6">
                   <img src={Hero1} alt="Hero_Img" className="img-effect" />
                </div>              
               </div>
            </div>
         </div>
       </div>
    </section>
    
)

export default Hero;