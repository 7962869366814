import React from 'react';

import ADMIN_DATA from './admin.data.js'

// import CollectionPreview from '../../components/collection-preview/collection-preview.component'
import MenuItem from '../../components/menu-item/menu-item.component'

import './admin.styles.scss'

class AdminPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collections: ADMIN_DATA
        };
    }

    render() {
        const {collections} = this.state;
        return (
            <div className="admin-page">
            <div className="directory-menu">
            {collections.map(({ id, ...otherCollectionProps }) => (
                <MenuItem key={id} {...otherCollectionProps} />
            ))}
        </div>
        </div>);
    }

}

export default AdminPage;