import * as React from 'react';

// import '@material/layout-grid/mdc-layout-grid.scss';

import { HashRouter, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';

 
import Header from './components/header/header.component'
import LandingPage from './pages/landing-page/landing-page.component'

// import Join from './components/ChatRoom/Join/Join'
import Chat from './components/ChatRoom/Chat/Chat'

import Dashboard from './pages/dashboard/dashboard.component'
import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component'
import AdminPage from './pages/admin/admin.component'


class App extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null
    };
  }


  render() {
    return !this.state.currentUser ? (
      <HashRouter>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route  path="/chat" component={Chat} />
          <Route path='/signin' component={SignInAndSignUpPage} />
          <Redirect to='/'/>
        </Switch>
      </HashRouter>
    ): (
      <div>
        <HashRouter>
        <Header currentUser={this.state.currentUser} />

          <Switch>
            <Route exact path='/dashboard' component={Dashboard} />
            
            <Route path='/dashboard/admin' component={AdminPage} />

            <Redirect to='/dashboard'/>
          </Switch>
          </HashRouter>

      </div>
    )

    
  }
 
}

export default App;
